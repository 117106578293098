import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { cx } from '@algolia/ui-components-shared';
var lastHorizontalSliderId = 0;
export function generateHorizontalSliderId() {
  return "uic-horizontal-slider-".concat(lastHorizontalSliderId++);
}
export function updateNavigationButtonsProps(_ref) {
  var listRef = _ref.listRef,
      nextButtonRef = _ref.nextButtonRef,
      previousButtonRef = _ref.previousButtonRef;

  if (!listRef.current || !previousButtonRef.current || !nextButtonRef.current) {
    return;
  }

  previousButtonRef.current.hidden = listRef.current.scrollLeft <= 0;
  nextButtonRef.current.hidden = listRef.current.scrollLeft + listRef.current.clientWidth >= listRef.current.scrollWidth;
}
export function createHorizontalSliderComponent(_ref2) {
  var createElement = _ref2.createElement;
  return function HorizontalSlider(props) {
    var _props$classNames;

    var listRef = props.listRef,
        nextButtonRef = props.nextButtonRef,
        previousButtonRef = props.previousButtonRef,
        sliderIdRef = props.sliderIdRef,
        updateNavigationButtonsProps = props.updateNavigationButtonsProps;

    var translations = _objectSpread({
      sliderLabel: 'Items',
      nextButtonLabel: 'Next',
      nextButtonTitle: 'Next',
      previousButtonLabel: 'Previous',
      previousButtonTitle: 'Previous'
    }, props.translations);

    var classNames = (_props$classNames = props.classNames) !== null && _props$classNames !== void 0 ? _props$classNames : {};

    function scrollLeft() {
      if (listRef.current) {
        listRef.current.scrollLeft -= listRef.current.offsetWidth * 0.75;
      }
    }

    function scrollRight() {
      if (listRef.current) {
        listRef.current.scrollLeft += listRef.current.offsetWidth * 0.75;
      }
    }

    if (props.items.length === 0) {
      return null;
    }

    return createElement("div", {
      className: cx('uic-HorizontalSlider-container', classNames.root)
    }, createElement("button", {
      ref: previousButtonRef,
      title: translations.previousButtonTitle,
      "aria-label": translations.previousButtonLabel,
      "aria-controls": sliderIdRef.current,
      className: cx('uic-HorizontalSlider-navigation', 'uic-HorizontalSlider-navigation--previous', classNames.navigation, classNames.navigationPrevious),
      onClick: function onClick(event) {
        event.preventDefault();
        scrollLeft();
      }
    }, createElement("svg", {
      width: "8",
      height: "16",
      viewBox: "0 0 8 16",
      fill: "none"
    }, createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.13809 0.744078C7.39844 1.06951 7.39844 1.59715 7.13809 1.92259L2.27616 8L7.13809 14.0774C7.39844 14.4028 7.39844 14.9305 7.13809 15.2559C6.87774 15.5814 6.45563 15.5814 6.19528 15.2559L0.861949 8.58926C0.6016 8.26382 0.6016 7.73618 0.861949 7.41074L6.19528 0.744078C6.45563 0.418641 6.87774 0.418641 7.13809 0.744078Z",
      fill: "currentColor"
    }))), createElement("ol", {
      className: cx('uic-HorizontalSlider-list', classNames.list),
      ref: listRef,
      tabIndex: 0,
      id: sliderIdRef.current,
      "aria-roledescription": "carousel",
      "aria-label": translations.sliderLabel,
      "aria-live": "polite",
      onScroll: updateNavigationButtonsProps,
      onKeyDown: function onKeyDown(event) {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollLeft();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollRight();
        }
      }
    }, props.items.map(function (item, index) {
      return createElement("li", {
        key: item.objectID,
        className: cx('uic-HorizontalSlider-item', classNames.item),
        "aria-roledescription": "slide",
        "aria-label": "".concat(index + 1, " of ").concat(props.items.length)
      }, createElement(props.itemComponent, {
        item: item
      }));
    })), createElement("button", {
      ref: nextButtonRef,
      title: translations.nextButtonTitle,
      "aria-label": translations.nextButtonLabel,
      "aria-controls": sliderIdRef.current,
      className: cx('uic-HorizontalSlider-navigation', 'uic-HorizontalSlider-navigation--next', classNames.navigation, classNames.navigationNext),
      onClick: function onClick(event) {
        event.preventDefault();
        scrollRight();
      }
    }, createElement("svg", {
      width: "8",
      height: "16",
      viewBox: "0 0 8 16",
      fill: "none"
    }, createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0.861908 15.2559C0.601559 14.9305 0.601559 14.4028 0.861908 14.0774L5.72384 8L0.861908 1.92259C0.601559 1.59715 0.601559 1.06952 0.861908 0.744079C1.12226 0.418642 1.54437 0.418642 1.80472 0.744079L7.13805 7.41074C7.3984 7.73618 7.3984 8.26382 7.13805 8.58926L1.80472 15.2559C1.54437 15.5814 1.12226 15.5814 0.861908 15.2559Z",
      fill: "currentColor"
    }))));
  };
}